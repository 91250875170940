/* eslint-disable import/prefer-default-export */

const invoiceFileValues = {
  PDF: 'attachPdf',
  PDF_FINGO: 'attachPdfFingo',
  SIGNED_INVOICE: 'attachSignedInvoice',
  PURCHASE_ORDER: 'attachPurchaseOrder',
  HES: 'attachHes',
  PAYMENT_STATUS: 'attachPaymentStatus',
  SHIPPING_GUIDEBOOK: 'attachShippingGuidebook',
  PORTAL_SCREENSHOT: 'attachPortalScreenshot',
  OTHER: 'attachOther',
  XML: 'attachXml',
  AEC: 'attachAec',
};

export const documentToAttachableList = (document) => {
  const attachables = [];
  const assignments = document.assignmentSet || [];
  if (assignments[0]?.certificate) {
    attachables.push({
      id: 'attachCessionCertificate',
      value: 'attachCessionCertificate',
      documentType: 'attachCessionCertificate',
      label: 'Certificado de cesión',
      companyDocument: assignments[0],
      url: assignments[0].certificate.url,
    });
  }
  const documents = document.company.documents || [];
  const contract = documents.find(({ documentType }) => documentType === 'contract');
  if (contract) {
    attachables.push({
      id: 'attachFrameworkContract',
      value: 'attachFrameworkContract',
      documentType: 'companyFrameworkContract',
      label: 'Contrato marco',
      companyDocument: contract,
      url: contract.lastFile,
    });
  }
  document.documents.forEach((file) => {
    attachables.push({
      id: file.id,
      value: invoiceFileValues[file.type.code],
      documentType: invoiceFileValues[file.type.code],
      label: file.type.name,
      companyDocument: file,
      url: file.file.url,
    });
  });
  return attachables;
};
